import { useAuthStore } from "~/store/auth"
import { storeToRefs } from "pinia"

export const useCompany = () => {
    const { user } = storeToRefs(useAuthStore())
    if (user?.value?.company) {
        // Simular não ter empresa
        // return ""
        
        return user.value.company        
    } else {
        return ""
    }
};
